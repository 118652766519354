import { Injectable } from "@angular/core";
import axios, { AxiosResponse } from "axios";
import { User } from "src/app/core/entities/model/user.entity";
import { environment } from 'src/environments/environment';

type LoginParams = {
    email_or_phone_number: string,
    password: string
}

type AuthCheckResponse = {
    message: string,
    user: User
}

@Injectable()
export class AuthRepository {
    private http = axios.create({
        baseURL: environment.api_url
    });

    constructor() { }

    private getToken(): String|null
    {
        return window.localStorage.getItem(environment.api_token_identifier);
    }
    
    public async authCheck(): Promise<AxiosResponse<AuthCheckResponse, any>>
    {
        const token = this.getToken();
        return this.http.get('/auth/check', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    
    public async login(data: LoginParams|FormData): Promise<AxiosResponse<any, any>> 
    {
        return this.http.post('/login', data);
    }
    
    public async logout(): Promise<AxiosResponse>
    {
        const token = this.getToken();
        return this.http.post('/logout', null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
