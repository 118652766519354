import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, provideState, provideStore } from '@ngrx/store';
import AuthenticationGuard from './core/guards/authentication.guard';
import { AuthRepository } from './data/repositories/auth.repository';
import { authUserFeature } from './data/state/auth-user.state';
import { PermissionModule } from './directives/permission/permission.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PermissionModule
  ],
  providers: [
    AuthenticationGuard,
    AuthRepository,
    provideStore(),
    provideState(authUserFeature)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
