import { createFeature, createReducer, on, props } from '@ngrx/store';
import { createAction } from '@ngrx/store';
import { User } from 'src/app/core/entities/model/user.entity';

export interface AuthUserState {
    user: User|null
}

export const authUserAction = {
    setUser: createAction('[Auth User] set user', props<User>())
};

export const authUserFeature = createFeature({
	name: 'authUser',
	reducer: createReducer<AuthUserState>({
			user: null
		},
		on(authUserAction.setUser, (state: AuthUserState, user: User) => {
			return {...state, user};
		})
	),
});


// const reducer = createReducer<AuthUserState>({
//         user: null
//     },
//     on(actions.setUser, (state: AuthUserState, user: User) => {
//         return {...state, user}
//     })
// );

// const storeKeyName = 'authUser';
// const featureSelector = createFeatureSelector<AuthUserState>(storeKeyName);
// export const selectors = {
//     getUser: createSelector(
//         featureSelector,
//         (state: AuthUserState) => state.user
//     )
// }

// const authUserStore = { 
//     storeKeyName, 
//     actions, 
//     reducer, 
//     selectors 
// };

// export default authUserStore;