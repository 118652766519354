import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthRepository } from "src/app/data/repositories/auth.repository";
import { authUserAction } from "src/app/data/state/auth-user.state";

@Injectable()
export default class AuthenticationGuard {
	
  public constructor(
    protected authRepo: AuthRepository,
    protected store: Store,
    protected router: Router
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> 
  {
    try {
      const { data } = await this.authRepo.authCheck();
      this.store.dispatch(authUserAction.setUser(data.user));
      return true;
    } catch (error) {
      this.router.navigate(['login']);
      return false;
    }
  }
}