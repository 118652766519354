import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // standalone: true,
  selector: '[hasPermissionTo]'
})
export class PermissionDirective implements OnInit {

  
  @Input() set hasPermissionTo(value: string|null) {
    console.log(value, 'custom gan')
  };
  
  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    // this.el.nativeElement.style.backgroundColor = 'yellow';
  }

  public ngOnInit(): void {
    console.log(this.hasPermissionTo);
  }
}
