import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import AuthenticationGuard from './core/guards/authentication.guard';
// import { authenticationGuard } from './core/guards/authentication.guard';

const routes: Routes = [
  {
    path: 'redirect',
    loadChildren: () => import('./pages/redirect/redirect.module').then(m => m.RedirectModule)
    // canActivate: []
  },
  {
    path: '',
    // loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    redirectTo: '/login',
    pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'detail-page/:id',
    loadChildren: () => import('./page/detail-page/detail-page.module').then(m => m.DetailPageModule)
  },

  {
    path: 'reservation-detail/:id',
    loadChildren: () => import('./pages/reservation-detail/reservation-detail.module').then(m => m.ReservationDetailModule)
  },

  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'faq/detail',
    loadChildren: () => import('./pages/faq/detail/detail.module').then(m => m.DetailModule)
  },
  {
    path: 'event/:id',
    loadChildren: () => import('./pages/event/detail/detail.module').then(m => m.DetailModule)
  },

  // Settings
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthenticationGuard]
  },
  
  {
    path: 'friends',
    loadChildren: () => import('./pages/friends/friends.module').then(m => m.FriendsModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'settings-profile',
    loadChildren: () => import('./page/settings-profile/settings-profile.module').then(m => m.SettingsProfileModule)
  },

  {
    path: 'faq-content',
    loadChildren: () => import('./page/faq/faq.module').then(m => m.FaqModule)
  },

  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },

  {
    path: 'modal',
    loadChildren: () => import('./page/modal/modal.module').then(m => m.ModalModule)
  },

  {
    path: 'review-history',
    loadChildren: () => import('./pages/review-history/review-history.module').then(m => m.ReviewHistoryModule)
  },
  
  {
    path: 'reservation',
    loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
